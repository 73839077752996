import { useMemo, useState } from "react";

import ApiResourceCollection from "../models/ApiResourceCollection";
import { ClaimDocument } from "../models/Document";
import { ClaimsLogic } from "./useClaimsLogic";
import EmployerClaimReview from "../models/EmployerClaimReview";
import EmployersApi from "../api/EmployersApi";
import { ErrorsLogic } from "./useErrorsLogic";
import { LeaveAdminsLogic } from "./useLeaveAdminsLogic";
import { PortalFlow } from "./usePortalFlow";
import { UsersLogic } from "./useUsersLogic";
import { VerificationType } from "../models/User";
import { get } from "lodash";
import { isFeatureEnabled } from "src/services/featureFlags";
import routes from "../routes";
import tracker from "../services/tracker";

const useEmployersLogic = ({
  errorsLogic,
  clearClaims,
  portalFlow,
  setActiveParams,
  setUser,
}: {
  errorsLogic: ErrorsLogic;
  clearClaims: ClaimsLogic["clearClaims"];
  portalFlow: PortalFlow;
  setActiveParams: LeaveAdminsLogic["setActiveParams"];
  setUser: UsersLogic["setUser"];
}) => {
  const [claim, setEmployerClaim] = useState<EmployerClaimReview | null>(null);
  const [claimDocumentsMap, setClaimDocumentsMap] = useState<
    Map<string, ApiResourceCollection<ClaimDocument>>
  >(new Map());
  const employersApi = useMemo(() => new EmployersApi(), []);

  // TODO (PFMLPB-17702): removal of feature flag
  const isMTCFeatureFlagEnabled = isFeatureEnabled(
    "enableLeaveAdminMTCVerification"
  );

  /**
   * Associate employer FEIN with logged in user
   */
  const addEmployer = async (data: { employer_fein: string }) => {
    errorsLogic.clearErrors();

    try {
      const userLeaveAdministrator = await employersApi.addEmployer(data);
      const params = { employer_id: userLeaveAdministrator.employer_id };

      // Setting user to undefined to require fetching updated
      // user_leave_administrators before navigating to either
      // the Verify Contributions page, Verify MTC, or Can't Verify page
      setUser(undefined);

      // Pass employer/ leave admin data through to /flows/employer.ts
      // so they can be used on conditional guard functions for routing
      portalFlow.goToNextPage({ leaveAdmin: userLeaveAdministrator }, params);
    } catch (error) {
      errorsLogic.catchError(error);
    }
  };

  /**
   * Retrieve claim from the API and set application errors if any
   */
  const loadClaim = async (absenceId: string) => {
    if (claim && claim.fineos_absence_id === absenceId) return;
    errorsLogic.clearErrors();

    try {
      const { claim } = await employersApi.getClaim(absenceId);

      setEmployerClaim(claim);
    } catch (error) {
      const employer_id = get(error, "responseData.employer_id");
      const has_verification_data = get(
        error,
        "responseData.has_verification_data"
      );
      const has_verified_leave_admin = get(
        error,
        "responseData.has_verified_leave_admin"
      );

      if (
        typeof employer_id === "string" &&
        typeof has_verification_data === "boolean"
      ) {
        // Leave admin was prevented from loading the claim, which we interpret to mean
        // as they need to still verify their organization:
        handleUnverifiedLeaveAdmin(
          employer_id,
          has_verification_data,
          has_verified_leave_admin
        );
      } else {
        errorsLogic.catchError(error);
      }
    }
  };

  /**
   * Retrieve documents from the API and set application errors if any
   */
  const loadDocuments = async (absenceId: string) => {
    if (claimDocumentsMap.has(absenceId)) return;
    errorsLogic.clearErrors();

    try {
      const { documents } = await employersApi.getDocuments(absenceId);
      const loadedClaimDocumentsMap = new Map(claimDocumentsMap.entries());
      loadedClaimDocumentsMap.set(absenceId, documents);

      setClaimDocumentsMap(loadedClaimDocumentsMap);
    } catch (error) {
      errorsLogic.catchError(error);
    }
  };

  /**
   * Load withholding data from the API and set app errors if any.
   */
  const loadWithholding = async (employerId: string) => {
    try {
      return await employersApi.getWithholding(employerId);
    } catch (error) {
      errorsLogic.catchError(error);
    }
  };

  /**
   * Download document from the API and set app errors if any.
   */
  const downloadDocument = async (
    document: ClaimDocument,
    absenceId: string
  ) => {
    errorsLogic.clearErrors();
    try {
      return await employersApi.downloadDocument(absenceId, document);
    } catch (error) {
      errorsLogic.catchError(error);
    }
  };

  /**
   * Submit claim review to the API and set application errors if any
   */
  const submitClaimReview = async (
    absenceId: string,
    data: { [key: string]: unknown }
  ) => {
    errorsLogic.clearErrors();

    try {
      await employersApi.submitClaimReview(absenceId, data);

      // Clear the cached claim data, most notably is_reviewable and
      // managed_requirements, so that the claim's dashboard status
      // routing remain accurate.
      setEmployerClaim(null);
      clearClaims();

      const params = { absence_id: absenceId };
      portalFlow.goToNextPage({}, params);
    } catch (error) {
      errorsLogic.catchError(error);
    }
  };

  /**
   * Submit employer review form state and set validation errors if any
   */
  const validateClaimReviewFormState = async (
    absenceId: string,
    data: { [key: string]: unknown }
  ) => {
    errorsLogic.clearErrors();

    try {
      await employersApi.validateClaimReviewFormState(absenceId, data);
    } catch (error) {
      errorsLogic.catchError(error);
    }
  };

  /**
   * Submit withholding data to the API for verification
   */
  const submitWithholding = async (
    data: {
      employer_id: string;
      withholding_amount: number;
      withholding_quarter: string;
    },
    next?: string
  ) => {
    errorsLogic.clearErrors();

    try {
      const payload = isMTCFeatureFlagEnabled
        ? { ...data, verification_type: VerificationType.withholding }
        : data;

      const { user } = await employersApi.submitWithholding(payload);
      const params = { employer_id: data.employer_id, next };
      // TODO (PFMLPB-5729): remove setUser call - no longer needed (just active params)
      // Update user state so the employer now shows as verified:
      setUser(user);
      // Unset active leave admin params so we trigger leave admin data refresh (loadAll in useLeaveAdminsLogic)
      setActiveParams(undefined);
      portalFlow.goToNextPage({}, params);
    } catch (error) {
      errorsLogic.catchError(error);
    }
  };

  /**
   *
   * @param data Submit MTC ID number to the API for verification
   */
  const submitMTCNumber = async (
    data: { employer_id: string; mtc_number: string },
    next?: string
  ) => {
    try {
      const payload = { ...data, verification_type: VerificationType.mtc };

      const { user } = await employersApi.submitWithholding(payload);
      const params = { employer_id: data.employer_id, next };
      // TODO (PFMLPB-5729): remove setUser call - no longer needed (just active params)
      // Update user state so the employer now shows as verified:
      setUser(user);
      // Unset active leave admin params so we trigger leave admin data refresh (loadAll in useLeaveAdminsLogic)
      setActiveParams(undefined);
      portalFlow.goToNextPage({}, params);
    } catch (error) {
      errorsLogic.catchError(error);
    }
  };

  /**
   * Redirect to either the Verify Contributions or Cannot Verify page
   * based on if the UserLeaveAdministrator is verifiable.
   */
  const handleUnverifiedLeaveAdmin = (
    employer_id: string,
    has_verification_data: boolean,
    has_verified_leave_admin?: boolean
  ) => {
    // TODO (PFMLPB-17702): Cleanup Feature Flag
    const mtc_enabled = isFeatureEnabled("enableLeaveAdminMTCVerification");

    tracker.trackEvent("LeaveAdminForbiddenError", {
      employerId: employer_id,
      hasVerificationData: has_verification_data.toString(),
      ...(mtc_enabled
        ? { hasVerifiedLeaveAdmin: (has_verified_leave_admin ?? "").toString() }
        : {}),
    });

    if (has_verification_data) {
      return portalFlow.goTo(
        routes.employers.verifyContributions,
        {
          employer_id,
          next: portalFlow.pathWithParams,
        },
        { redirect: true }
      );
    }

    if (mtc_enabled && !has_verified_leave_admin) {
      return portalFlow.goTo(
        routes.employers.verifyMTC,
        {
          employer_id,
          next: portalFlow.pathWithParams,
        },
        { redirect: true }
      );
    }

    // Regardless of whether there is a verified leave admin on the organization, we know the employer has no pfml contributions.
    // At this point we know the employer cannot be verified.
    return portalFlow.goTo(
      routes.employers.cannotVerify,
      {
        employer_id,
      },
      { redirect: true }
    );
  };

  return {
    addEmployer,
    claim,
    claimDocumentsMap,
    downloadDocument,
    loadClaim,
    loadDocuments,
    loadWithholding,
    submitClaimReview,
    submitWithholding,
    submitMTCNumber,
    validateClaimReviewFormState,
  };
};

export default useEmployersLogic;
